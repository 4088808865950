import "./scss/style.scss";

const paragrafos = document.querySelectorAll("p");
const toggleButton = document.getElementById("toggleButton");
const backToTop = document.getElementById("backToTop");
let index = 0;

toggleButton.addEventListener("click", () => {
  if (index < 1) {
    index++;
    paragrafos.forEach((paragrafo, i) => {
      if (i > 1) {
        paragrafo.classList.remove("hidden");
      }
    });
    toggleButton.textContent = "view less";
    backToTop.classList.add("active");
  } else {
    paragrafos.forEach((paragrafo, i) => {
      if (i > 1) {
        paragrafo.classList.add("hidden");
      }
    });
    toggleButton.textContent = "view more";
    backToTop.classList.remove("active");
    index = 0;
  }
});
